import React, { useState } from 'react'
import styled from '@emotion/styled';

const ContenedorMensaje = styled.p`
    background-color: red;
    color: #FFF;
    width: 50%;
    font-size: 1rem;
    padding: 1rem;
    margin: 0;
    margin-right: 20%;
    text-align: center;
    font-weight: bold;
`;

const ResultadoMensaje = styled.p`
    background-color: green;
    color: #FFF;
    width: 50%;
    font-size: 1rem;
    padding: 1rem;
    margin: 0;
    margin-right: 20%;
    text-align: center;
    font-weight: bold;
`;

export default function ContactForm ({guardarContacto, resultado}){
   
    // Definir el state del form
    const [ datos, guardarDatos ] = useState({
        nombre: '',
        telefono: '',
        empresa: '',
        email: '',
        mensaje: ''
    });

    // Definir state de error
    const [ error, guardarError ] = useState(false);

    // Extrae datos del form
    const { nombre, telefono, empresa, email, mensaje } = datos;

    const _hanbleSubmit = e =>{
        e.preventDefault()
        
        // Validar campos
        if(nombre.trim() === ''){
            guardarError(true);
            return;
        }

        guardarError(false);

        // Enviar form
        guardarContacto(datos);

        // Resetear formulario
        guardarDatos({
            nombre: '',
            telefono: '',
            empresa: '',
            email: '',
            mensaje: ''
        });
    }

    const _hanbleChange = e =>{
        guardarDatos({
            ...datos,
            [e.target.name]: e.target.value
        });
    }

        return (
            <form onSubmit={_hanbleSubmit} id="frm-contacto" uk-scrollspy="cls: uk-animation-slide-bottom-small; repeat: true; delay: 300">
                    <div className="form-row">
                        <label htmlFor="nombre">Nombre Completo</label>
                        <input type="text" name="nombre" id="nombre" 
                        required
                        onChange={_hanbleChange}
                        value={nombre}
                        />
                    </div>
                    <div className="form-row">
                        <label htmlFor="telefono">Teléfono</label>
                        <input type="tel" id="telefono" name="telefono" 
                        required
                        onChange={_hanbleChange}   
                        value={telefono} 
                        />
                    </div>

                    <div className="form-row">
                        <label htmlFor="empresa">Nombre Empresa (Opcional)</label>
                        <input type="text" id="empresa" name="empresa"
                        onChange={_hanbleChange}
                        value={empresa}
                        />
                    </div>
                    <div className="form-row">
                        <label htmlFor="email">E-mail</label>
                        <input type="email" id="email" name="email" required 
                        onChange={_hanbleChange}
                        value={email}
                        />
                    </div>
                    <div className="form-row-full">
                        <label htmlFor="mensaje">Mensaje</label>
                        <textarea name="mensaje" id="mensaje" 
                        required
                        value={mensaje}
                        onChange={_hanbleChange}></textarea>
                    </div>

                    {error ? <ContenedorMensaje>Hubo en error.</ContenedorMensaje> : null}
                    {resultado.length > 0 ? <ResultadoMensaje>{resultado}</ResultadoMensaje> : null}

                    <button id="btnForm">Enviar</button>
                </form>
        )
    
}
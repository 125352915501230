import React, { Component } from 'react'
import { Link } from 'react-router-dom'


class Home extends Component{

    render(){
        return(
            <div>
                   <div className="header-top">
                        <div className="container container-top">
                            <span className="phone">
                                <a href="Https://wa.me/18093173406" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-whatsapp"></i> 809-317-3406 &nbsp;&nbsp;|&nbsp;&nbsp;   829-920-3535
                            </a>
                            </span>
                            {/* <!-- <span className="email"> </span> --> */}
                            <nav className="topmenu">
                                <Link to={{
                                    pathname: "/",
                                    hash: "#"
                                }} uk-scroll='true' rel="noopener noreferrer">Inicio</Link>
                                <Link to={{
                                    pathname: "/",
                                    hash: "#portafolio"
                                }} uk-scroll='{"offset": 100}' style={{ display: 'none' }}>Portafolio</Link>
                                <Link to={{
                                    pathname: "/",
                                    hash: "#hostdomi"
                                }} uk-scroll='{"offset": 100, "top": 100}'>Hostdomi</Link>
                                <Link to={{
                                    pathname: "/",
                                    hash: "#contacto"
                                }} uk-scroll='true'>Contacto</Link>
                            </nav>
                        </div>
                    </div>
    <header>
        <div className="header">

            <div className="container main-container">
                <div className="logo">
                    <Link to={{
                            pathname: "/",
                            hash: "#"
                                }} uk-scroll='true'><img src="assets/img/logo-gn.svg" alt="Hostdomi"/></Link>
                </div>
                <nav className="main-menu">
                    <Link to={{
                                    pathname: "/",
                                    hash: "#webapp"
                                }} className="hvrcenter" uk-scroll='true'>DESARROLLO WEB</Link>
                    <Link to={{
                                    pathname: "/",
                                    hash: "#socialmedia"
                                }} className="hvrcenter" uk-scroll='{"offset": 80, "top": 100}'>REDES SOCIALES</Link>
                    <Link to={{
                                    pathname: "/",
                                    hash: "#intmarketing"
                                }} className="hvrcenter" uk-scroll='true'>MARKETING DIGITAL</Link>
                    <Link to={{
                                    pathname: "/",
                                    hash: "#webhosting"
                                }} className="hvrcenter" uk-scroll='true'>WEB HOSTING</Link>
                    <Link to={{
                                    pathname: "/",
                                    hash: "#emailhosting"
                                }} className="hvrcenter" uk-scroll='true'>E-MAIL HOSTING</Link>
                </nav>
                <div className="redes-sociales">
                    <a href="https://www.instagram.com/hostdomi/" target="_blank" rel='noopener noreferrer'><i className="fab fa-instagram"></i></a>
                    <a href="https://www.facebook.com/hostdomi" target="_blank" rel='noopener noreferrer'><i className="fab fa-facebook-f"></i></a>
                </div>
            </div>

            <div className="slideshow">

                {/* <!-- Swiper --> */}
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <div className="container slide-textimage">
                                <div className="slide-text">
                                    <h1>DISEÑO WEB PROFESIONAL</h1>
                                    <p>Con las últimas tecnologías y mejores practicas de usabilidad y adaptable a diferentes dispositivos.</p>
                                    <button>Saber más...</button>
                                </div>
                                <div className="slide-image">
                                    <img src="assets/img/web-development.svg" alt="Diseño Web"/>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="container slide-textimage">
                                <div className="slide-text">
                                    <h1>REDES SOCIALES</h1>
                                    <p>La importancia de las redes sociales hoy día es incuestionable y su empresa puede y “debe” sacarles provecho como una manera nueva de conectar con sus clientes y potenciales clientes.</p>
                                    <button>Saber más...</button>
                                </div>
                                <div className="slide-image">
                                    <img src="assets/img/slide-social.svg" alt="Manejo de Redes Sociales"/>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="container slide-textimage">
                                <div className="slide-text">
                                    <h1>WEB HOSTING</h1>
                                    <p>Es crucial elegir una empresa de alojamiento web confiable para garantizar que tu sitio web permanezca seguro y accesible durante todo el año y contar con un soporte rápido ante cualquier necesidad.</p>
                                    <button>Saber más...</button>
                                </div>
                                <div className="slide-image">
                                    <img src="assets/img/web-hosting.svg" alt="Desarrollo Web"/>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <!-- Add Pagination --> */}
                    <div className="swiper-pagination"></div>
                    {/* <!-- Add Arrows --> */}
                    <div className="swiper-button-next swiper-button-white"></div>
                    <div className="swiper-button-prev swiper-button-white"></div>
                </div>

            </div>

        </div>

    </header>
    <section className="page-nosotros" id="hostdomi">
        <div className="container" uk-scrollspy="cls: uk-animation-fade; repeat: true; delay: 300">
            <h4>SOBRE NOSOTROS</h4>
            <h1>HOSTDOMI</h1>
            <p><b>Somos una empresa proveedora de soluciones en las <br/>diferentes areas de la tecnología web y aplicaciones para nuestros clientes.</b></p>

            <p>Ayudamos a desarrollar su negocio o servicios online, Somos una empresa expecialidad en el area y con bastante experiencia.</p>
        </div>
    </section>
    <section className="page-social">
        <div className="container socialcontainer">
            <div className="image-social" id="socialmedia" uk-scrollspy="cls: uk-animation-slide-left; repeat: true; delay: 300">
                <img src="assets/img/illustracion-social-media.svg" alt="Social Media"/>
            </div>
            <div className="social-text" uk-scrollspy="cls: uk-animation-slide-right; repeat: true; delay: 300">
                <h2>REDES SOCIALES</h2>
                <p>Son un canal de comunicación para las empresas, realizamos estrategias para captar nuevos clientes y es uno de los medios de comercialización más rentable hoy en dia, no pases por alto el poder de las redes sociales, contribuimos al exito
                    por medio de nuestro servicio de Community Manager.</p>
            </div>
        </div>
    </section>
    <section className="page-desarrollo" id='webapp'>
        <div className="container desarrollocontainer">
            <div className="desarrollo-text" uk-scrollspy="cls: uk-animation-slide-left; repeat: true; delay: 300">
                <h1>DESARROLLO DE APP MÓVILES</h1>
                <p>Las aplicaciones móviles para celulares y tablets son un cambio revolucionario hoy en dia para muchos negocios, las cuales permiten al usuario realizar actividades profesionales, acceder a servicios, aumentar las ventas entre otros, las
                    aplicaciones son una ventana abierta, es parte del desarrollo que dominina el mercado y todos quieren tener una aplicación movil de su empresa. </p>
            </div>
            <div className="imagedev" uk-scrollspy="cls: uk-animation-slide-right; repeat: true; delay: 300">
                <img src="assets/img/img-i-phone-2.png" alt="Desarrollo movil"/>
            </div>
        </div>
    </section>
    <section className="imarketing" id="intmarketing" uk-scrollspy="cls: uk-animation-slide-top-small; repeat: true; delay: 300">
        <div className="container">
            <h1>MARKETING DIGITAL</h1>
            <p>Creamos estrategias impresindible para las marcas (negocio) es una gran oportunidad de crecimiento, posicionamiento, ventas o captación de clientes que represente.</p>

            <img src="assets/img/internet-marketing-illustration.svg" alt="Marketing Digital"/>
        </div>
    </section>
    <section className="marketing-block">
        <div className="container mktblock" uk-scrollspy="target: > .marketing-items; cls: uk-animation-fade; delay: 500">
            <div className="marketing-items">
                <div className="imkimg">
                    <img src=" assets/img/seo-icon.svg " alt="SEO "/>
                </div>
                <div className="marketingtext ">
                    <span className="titulo ">SEO</span>
                    <p>Mejora tus resultados en los buscadores así como el contenido que debe o no aparecer.</p>
                </div>
            </div>
            <div className="marketing-items">
                <div className="imkimg">
                    <img src="assets/img/google-ads-icon.svg" alt="Google ADS "/>
                </div>
                <div className="marketingtext ">
                    <span className="titulo ">Google Ads</span>
                    <p>Anuncios para empresas, pymes y autónomos en los resultados de búsqueda de Google.</p>
                </div>
            </div>
            <div className="marketing-items">
                <div className="imkimg">
                    <img src="assets/img/faceboo-ads-icon.svg" alt="Facebook Ads"/>
                </div>
                <div className="marketingtext ">
                    <span className="titulo ">Facebook Ads</span>
                    <p>Anuncios de Facebook promocionamos páginas de Facebook, sitios web y aplicaciones móviles.</p>
                </div>
            </div>
            <div className="marketing-items">
                <div className="imkimg">
                    <img src="assets/img/ig-post-icon.svg" alt="Instagram Post"/>
                </div>
                <div className="marketingtext ">
                    <span className="titulo ">Instagram Post</span>
                    <p>Mejoramos tu presencia en las redes sociales y aumenta tu cartera de potenciales clientes.</p>
                </div>
            </div>
            <div className="marketing-items">
                <div className="imkimg">
                    <img src="assets/img/ig-video-icon.svg" alt="Video para Instagram "/>
                </div>
                <div className="marketingtext ">
                    <span className="titulo ">Video para Instagram </span>
                    <p>Grabación y edicion de videos profesionales para redes sociales.</p>
                </div>
            </div>
            <div className="marketing-items">
                <div className="imkimg">
                    <img src="assets/img/email-marketing-icon.svg" alt="E-mail Marketing"/>
                </div>
                <div className="marketingtext ">
                    <span className="titulo ">E-mail Marketing</span>
                    <p>Es una de las formas más efectivas de fidelizar clientes.</p>
                </div>
            </div>
        </div>
    </section>
    <section className="hosting" id="webhosting">
        <div className="container page-hosting">
            <div className="page-hostext" uk-scrollspy="cls: uk-animation-slide-left-small; repeat: true; delay: 300">
                <h1>WEB HOSTING</h1>
                <p>Nuestra plataforma de servidores cuenta con el mejor soporte para tu página web y emails corporativos, con la asistencia que necesitas.</p>
            </div>
            <div className="img-hosting" uk-scrollspy="cls: uk-animation-slide-right-small; repeat: true; delay: 300">
                <img src="assets/img/web-hosting-illustration.svg" alt="Web Hosting"/>
            </div>
        </div>
        <div className="container email-hosting" id="emailhosting">
            <div className="img-email" uk-scrollspy="cls: uk-animation-slide-left-small; repeat: true; delay: 300">
                <img src="assets/img/mail-hosting-illustration.svg" alt="E-mail Hosting"/>
            </div>
            <div className="page-email" uk-scrollspy="cls: uk-animation-slide-right-small; repeat: true; delay: 300">
                <h1>E-MAIL HOSTING</h1>
                <p>El servicio de correos de una empresa es uno de los servicios más críticos e importantes. Brindamos una gama de soluciones únicas, así como la combinación de algunas de ellas para que su empresa tenga la solución más adecuada.</p>
            </div>
        </div>
    </section>
    <section className="portafolio" id="portafolio">
        <div className="container" style={{ display:'none'}}>
            <h2>CASOS DE EXITOS</h2>

            <div>
                <ul className="casosExitos">
                    <li><a href="hostdomi.com" className="active" rel='noopener noreferrer'>Desarrollo Web</a></li>
                    <li><a href="hostdomi.com" rel='noopener noreferrer'>Desarollo App</a></li>
                    <li><a href="hostdomi.com" rel='noopener noreferrer'>Diseño UI / UX y Gráfico</a></li>
                    <li><a href="hostdomi.com" rel='noopener noreferrer'>Social Media</a></li>
                </ul>

                <ul className="excont">
                    <li><img src="assets/img/demo-porfolio1.png" alt="demo"/></li>
                    <li><img src="assets/img/demo-porfolio1.png" alt="demo"/></li>
                    <li><img src="assets/img/demo-porfolio1.png" alt="demo"/></li>
                    <li><img src="assets/img/demo-porfolio1.png" alt="demo"/></li>
                    <li><img src="assets/img/demo-porfolio1.png" alt="demo"/></li>
                    <li><img src="assets/img/demo-porfolio1.png" alt="demo"/></li>
                    <li><img src="assets/img/demo-porfolio1.png" alt="demo"/></li>
                    <li><img src="assets/img/demo-porfolio1.png" alt="demo"/></li>
                    <li><img src="assets/img/demo-porfolio1.png" alt="demo"/></li>
                </ul>
            </div>
        </div>
    </section>
    <section className="boletin">
        <div className="container page-boletin">
            <h3>Recibe información actualizada <br/> a través de nuestro boletín Informativo</h3>
            <form action="">
                <input type="text" name="email_boletin" id="email_boletin" placeholder="E-mail"/>
                <button id="btnBoletin">Enviar</button>
            </form>
        </div>
    </section>
            </div>
        );
    }
}

export default Home
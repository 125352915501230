import React from 'react';
import { Link } from 'react-router-dom';
import ContactForm from './ContactForm';
import Spinner from '../Component/Spinner';

const YEAR = new Date().getFullYear()

export default function Footer({guardarContacto, cargando, resultado}) {
    return (
        <footer>
        <section className="contacto" id="contacto">
            <div className="container page-contacto">
                <div className="contactext" uk-scrollspy="cls: uk-animation-slide-bottom-small; repeat: true; delay: 300">
                    <h2>CONTACTO</h2>
                    <p className="desc">Déjenos su solicitud y le responderemos lo mas pronto posible, disponemos de WhatsApp para una comunicación directa.</p>
                    <p><span className="email-phone">E-MAIL</span> <br/> <span className="correo"></span>
                    </p>
                    <p><span className="email-phone">TELÉFONO</span> <br/> <a href="Https://wa.me/18093173406" target="_blank" rel='noopener noreferrer'>+1 809-317-3406</a>
                    </p>
                    <div className="redes-sociales-contact">
                        <a href="https://www.instagram.com/hostdomi/" target="_blank" rel='noopener noreferrer'><i className="fab fa-instagram"></i></a>
                        <a href="https://www.facebook.com/hostdomi" target="_blank" rel='noopener noreferrer'><i className="fab fa-facebook-f"></i></a>
                        <a href="Https://wa.me/18093173406" target="_blank" rel='noopener noreferrer'><i className="fab fa-whatsapp"></i></a>
                    </div>

                </div>
               
               {cargando ? <Spinner/> :
                <ContactForm 
                    guardarContacto={guardarContacto}
                    resultado={resultado}
                />
               }
   

            </div>
        </section>
        <div className="container">
            <section className="footer">
                <div className="logo-footer">
                    <img src="assets/img/footer-logo.svg" alt="Hostdomi"/>
                </div>
                <div className="copy">
                    <p>
                        &copy; {YEAR} <b>HOSTDOMI.</b> Todos Los Derechos Reservados.
                    </p>
                </div>
            </section>
            <Link to={{
                                    pathname: "/",
                                    hash: "#"
                                }} className="btntop" uk-scroll='true' rel='noopener noreferrer'><i className="fas fa-chevron-up"></i></Link>
        </div>
    </footer>
    )
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';



import Home from './Pages/Home';
import Footer from './Component/Footer';


export default function App() {

  // State del formulario de contacto
 const [ contacto, guardarContacto ] = useState({});
 const [ cargando, guardarCargando ] = useState(false);
 const [ resultado, guardarResultado ] = useState('');

 useEffect( ()=>{
      
      if(Object.keys(contacto).length === 0)return;

      // console.log(contacto);

      const data = new FormData();
      data.append('empresa', 'DesarrolloWeb.com');

      

      const enviarForm = ()=> {

        const API_PATH = 'https://hostdomi.com/contact/process.php';


        guardarCargando(true);

        setTimeout( async()=>{
          
          const respuesta = await axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: contacto
          });
            // .then(result => {
            //   console.log(result);
            // })
            // .catch(error => console.log);
  
          guardarResultado(respuesta.data);

          guardarCargando(false);
        },2000);
      

      };
      enviarForm();
      

 },[contacto]);

    return (
      <div className="App">
        <Home/>
        <Footer 
          guardarContacto={guardarContacto} 
          cargando={cargando}
          resultado={resultado}
        />
      </div>
    );
  
}


